import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { EmptyState } from "../components/EmptyState";
import { Office } from "../components/Office";
import { Skeleton } from "../components/Skeleton";
import { FilterContext } from "../providers/FilterProvider";

export function Insights() {
  const { insights, loading, error } = useContext(FilterContext);
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="pt-2 pb-6 px-4 sm:px-6 md:px-8 md:py-6">
        <Skeleton />
      </div>
    );
  }

  if (error != null) {
    return (
      <EmptyState
        icon="noResult"
        title={t(`${error}.title`)}
        text={t(`${error}.text`)}
      />
    );
  }

  if (insights == null) {
    return (
      <EmptyState
        icon="report"
        title={t("emptyState:firstState.title")}
        text={t("emptyState:firstState.text")}
      />
    );
  }

  if (insights.totalRecords === 0) {
    return (
      <EmptyState
        icon="noResult"
        title={t("emptyState:noResults.title")}
        text={t("emptyState:noResults.text")}
      />
    );
  }

  return (
    <div className="pt-2 pb-6 md:py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {insights.subjectResponse.records.map((subject) => {
          const key = `s${subject.subject.id}`;

          return (
            <div className="mt-16 first:mt-0" key={key}>
              <div className="flex items-center mb-4 px-6">
                <h1 className="text-xl font-semibold text-gray-900">
                  {subject.subject.title}
                </h1>
              </div>

              {subject.officeResponse.records.map((office) => (
                <Office
                  key={`${key}_o${office.office.id}`}
                  keyPrefix={key}
                  office={office}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
