import React, { ReactElement, useState } from "react";

import { ChevronDown } from "../icons/ChevronDown";
import { ChevronRight } from "../icons/ChevronRight";
import { Stats } from "./Stats";

interface Props {
  actualAvailableSlots?: number;
  children: ReactElement;
  className?: string;
  title: string;
  duration?: number;
  slots?: number;
}

export function Card(props: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { actualAvailableSlots, children, className = "", title, slots, duration } = props;

  function toggleIsCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div className={`rounded overflow-hidden shadow-md ${className}`.trim()}>
      <div
        className={`bg-gray-200 flex ${
          !isCollapsed ? "border-b border-gray-200" : ""
        }`}
      >
        <button
          className="px-6 py-5 flex flex-1 items-center select-none focus:outline-none"
          onClick={toggleIsCollapsed}
        >
          {isCollapsed ? <ChevronRight /> : <ChevronDown />}
          <h3 className="text-lg leading-6 font-medium text-gray-900 ml-4">
            {title}
          </h3>
          {
            actualAvailableSlots !== undefined && duration !== undefined && slots !== undefined && (
              <Stats actualAvailableSlots={actualAvailableSlots} duration={duration} slots={slots} />
            )
          }
        </button>
      </div>
      {!isCollapsed && children}
    </div>
  );
}
