import { QueryResult } from "@apollo/client";

export function composeQueries<A, B, C, D, E, F, G>(
  queryA: QueryResult<A>,
  queryB: QueryResult<B>,
  queryC: QueryResult<C>,
  queryD: QueryResult<D>,
  queryE: QueryResult<E>,
  queryF: QueryResult<F>,
  queryG: QueryResult<G>
): { data?: A & B & C & D & E & F & G; loading: boolean; error: boolean };
export function composeQueries<A, B, C, D, E, F>(
  queryA: QueryResult<A>,
  queryB: QueryResult<B>,
  queryC: QueryResult<C>,
  queryD: QueryResult<D>,
  queryE: QueryResult<E>,
  queryF: QueryResult<F>
): { data?: A & B & C & D & E & F; loading: boolean; error: boolean };
export function composeQueries<A, B, C, D, E>(
  queryA: QueryResult<A>,
  queryB: QueryResult<B>,
  queryC: QueryResult<C>,
  queryD: QueryResult<D>,
  queryE: QueryResult<E>
): { data?: A & B & C & D & E; loading: boolean; error: boolean };
export function composeQueries<A, B, C, D>(
  queryA: QueryResult<A>,
  queryB: QueryResult<B>,
  queryC: QueryResult<C>,
  queryD: QueryResult<D>
): { data?: A & B & C & D; loading: boolean; error: boolean };
export function composeQueries<A, B, C>(
  queryA: QueryResult<A>,
  queryB: QueryResult<B>,
  queryC: QueryResult<C>
): { data?: A & B & C; loading: boolean; error: boolean };
export function composeQueries<A, B>(
  queryA: QueryResult<A>,
  queryB: QueryResult<B>
): { data?: A & B; loading: boolean; error: boolean };
export function composeQueries<A>(
  queryA: QueryResult<A>
): { data?: A; loading: boolean; error: boolean };
export function composeQueries(
  ...queries: QueryResult[]
): { data?: any; loading: boolean; error: boolean } {
  return {
    data: queries.reduce(
      (a, b) => ({
        ...a,
        ...b.data,
      }),
      {}
    ),
    loading: queries.some((query) => query.loading),
    error: queries.some((query) => query.loading),
  };
}
