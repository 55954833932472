import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import da from "./da.json";
import en from "./en.json";
import fr from "./fr.json";
import nl from "./nl.json";

const resources = {
  da,
  en,
  fr,
  nl,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: "common",
    ns: "common",
    preload: ["en"],
    fallbackLng: "en",
    whitelist: ["da", "en", "fr", "nl"],
    nonExplicitWhitelist: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
