import React from "react";
import { useTranslation } from "react-i18next";

import loader from "../assets/loader.gif";
import { CenterMessage } from "./CenterMessage";

export function Loader() {
  const { t } = useTranslation();

  return (
    <CenterMessage
      icon={<img src={loader} alt="" className="h-24" />}
      title=""
      text={t("loading")}
    />
  );
}
