import React, { ReactElement } from "react";

import { DocReport, SadEmoji, Search } from "../icons";
import { CenterMessage } from "./CenterMessage";

type Icon = "noResult" | "search" | "report";
interface Props {
  icon: Icon;
  title: string;
  text: string;
  buttonText?: string;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
}

const ICON_MAP: Record<Icon, ReactElement> = {
  noResult: <SadEmoji />,
  report: <DocReport />,
  search: <Search />,
};

export function EmptyState(props: Props) {
  const { title, text, icon, buttonText, onClick } = props;

  return (
    <CenterMessage icon={ICON_MAP[icon]} text={text} title={title}>
      <>
        {buttonText && onClick && (
          <button
            className="px-6 py-5 flex flex-1 items-center select-none focus:outline-none"
            onClick={onClick}
          >
            {buttonText}
          </button>
        )}
      </>
    </CenterMessage>
  );
}
