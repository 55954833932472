import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { clearToken, getToken } from "../utils/token";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map((error) => {
      // TODO: This is temporary, awaits better error response in B/E
      if (error.message.startsWith("Access denied!")) {
        clearToken();
        window.location.replace("");
      }

      return console.error(`[GraphQL error]: Message: ${error.message}`);
    });

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: authLink.concat(errorLink).concat(httpLink),
});
