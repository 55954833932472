import { useQuery } from "@apollo/client";
import { format, isAfter } from "date-fns";
import { ValidationErrors } from "final-form";
import sortBy from "lodash.sortby";
import React, { ReactElement, useContext, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { NumberParam, useQueryParam } from "use-query-params";

import { CheckboxGroup } from "../components/CheckboxGroup";
import { Datepicker } from "../components/Datepicker";
import { FilterSkeleton } from "../components/FilterSkeleton";
import { SelectDropdown } from "../components/SelectDropdown";
import {
  CONTACTS,
  MEETING_TYPES,
  OBSERVATION_DATE,
  OFFICES,
  SUBJECTS,
} from "../graphql/queries";
import { Location, Office, Phone, Video } from "../icons";
import { defaultFilters, FilterContext } from "../providers/FilterProvider";
import {
  Contacts,
  MeetingTypes,
  ObservationDate,
  Offices,
  Subjects,
} from "../types/api";
import { composeQueries } from "../utils/composeQueries";

function mapOptions(options?: any[], labelKey: string = "title") {
  return sortBy(
    (options ?? []).map((option) => ({
      label: option[labelKey],
      value: option.id,
    })),
    "label"
  );
}

function formatToDateString(date?: string | Date): string {
  return format(date !== undefined ? new Date(date) : new Date(), "yyyy-MM-dd");
}

export const ICON_MAP: Record<string, ReactElement> = {
  OFFICE: <Office />,
  PHONE: <Phone />,
  VIDEO: <Video />,
  ON_LOCATION: <Location />,
};

export function Filters() {
  const { t } = useTranslation(["filters", "common"]);
  const [officeId, setOfficeId] = useQueryParam("officeId", NumberParam);
  const { onFilter, onReset } = useContext(FilterContext);

  const { data, loading } = composeQueries(
    useQuery<Contacts>(CONTACTS),
    useQuery<MeetingTypes>(MEETING_TYPES),
    useQuery<ObservationDate>(OBSERVATION_DATE),
    useQuery<Offices>(OFFICES),
    useQuery<Subjects>(SUBJECTS)
  );

  const contacts = mapOptions(data?.contacts, "name");
  const meetingTypes = data?.meetingTypes ?? [];
  const offices = mapOptions(data?.offices);
  const subjects = mapOptions(data?.subjects);

  const end = formatToDateString(data?.observationDate?.end);
  const start = formatToDateString(data?.observationDate?.start);

  const isDisabled = false;

  function handleValidate(values: any): ValidationErrors | undefined {
    return {
      end: isAfter(new Date(values.end), new Date(values.start))
        ? undefined
        : t("end.error.range"),
    };
  }

  async function onSubmit(values: any) {
    onFilter(values);
  }

  useEffect(() => {
    if (officeId != null) {
      // TODO: set officeId filter and defaultValues
      // onFilter({ ...defaultFilters, officeIds: [officeId] });
      setOfficeId(undefined);
    }
  }, [officeId, onFilter, setOfficeId]);

  if (loading) {
    return <FilterSkeleton />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...defaultFilters,
        end,
        start,
      }}
      validate={handleValidate}
    >
      {({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="start">
            {({ input }) => (
              <Datepicker
                label={t("start.label")}
                max={end}
                min={start}
                onChange={input.onChange}
                value={input.value}
              />
            )}
          </Field>
          <div className="mt-4">
            <Field name="end" className="mt-4">
              {({ input, meta }) => (
                <>
                  <Datepicker
                    invalid={Boolean(meta.error)}
                    label={t("end.label")}
                    max={end}
                    min={start}
                    onChange={input.onChange}
                    value={input.value}
                  />
                  {meta.error && (
                    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="mt-4">
            <Field name="subjectIds">
              {({ input }) => (
                <SelectDropdown
                  label={t("subjects.label")}
                  onChange={input.onChange}
                  options={subjects}
                  value={input.value}
                />
              )}
            </Field>
          </div>
          <div className="mt-4">
            <Field name="officeIds" className="mt-4">
              {({ input }) => (
                <SelectDropdown
                  label={t("offices.label")}
                  onChange={input.onChange}
                  options={offices}
                  value={input.value}
                />
              )}
            </Field>
          </div>
          <div className="mt-4">
            <Field name="contactIds">
              {({ input }) => (
                <SelectDropdown
                  label={t("contacts.label")}
                  onChange={input.onChange}
                  options={contacts}
                  value={input.value}
                  optimizeList
                />
              )}
            </Field>
          </div>
          <Field name="meetingTypes">
            {({ input }) => (
              <CheckboxGroup
                label={t("meetingTypes.label")}
                value={input.value}
                onChange={input.onChange}
                options={meetingTypes.map((meetingType: any) => ({
                  icon: ICON_MAP[meetingType],
                  label: t(`common:meetingTypes.${meetingType}`),
                  value: meetingType,
                }))}
              />
            )}
          </Field>
          <div className="mt-8 flex justify-between">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border-none outline:none text-sm leading-5text-gray-500 hover:text-gray-400 focus:outline-none transition ease-in-out duration-150"
              onClick={() => {
                form.reset();
                onReset();
              }}
            >
              {t("reset.label")}
            </button>
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="submit"
                disabled={isDisabled}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 rounded-md text-white bg-brand focus:outline-none transition-opacity ease-in-out duration-150${
                  isDisabled ? " opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {t("submit.label")}
              </button>
            </span>
          </div>
        </form>
      )}
    </Form>
  );
}
