import { gql } from "@apollo/client";

export const INSIGHTS = gql`
  query Insights($params: InsightsParams!, $filters: InsightsFilters) {
    insights(params: $params, filters: $filters) {
      start
      end
      totalRecords
      contactResponse {
        actualAvailableSlots
        contact {
          id
          externalId
          firstName
          lastName
        }
        slots
        duration
      }
      subjectResponse {
        records {
          subject {
            id
            title
          }
          actualAvailableSlots
          slots
          duration
          officeResponse {
            records {
              office {
                id
                externalId
                title
              }
              actualAvailableSlots
              slots
              duration
              meetingTypeResponse {
                records {
                  meetingType
                  actualAvailableSlots
                  slots
                  duration
                  contactResponse {
                    actualAvailableSlots
                    contact {
                      id
                      externalId
                      firstName
                      lastName
                    }
                    slots
                    duration
                  }
                }
                contactResponse {
                  actualAvailableSlots
                  contact {
                    id
                    externalId
                    firstName
                    lastName
                  }
                  slots
                  duration
                }
              }
            }
            contactResponse {
              actualAvailableSlots
              contact {
                id
                externalId
                firstName
                lastName
              }
              slots
              duration
            }
          }
        }
        contactResponse {
          actualAvailableSlots
          contact {
            id
            externalId
            firstName
            lastName
          }
          slots
          duration
        }
      }
    }
  }
`;

export const CONTACTS = gql`
  query Contacts {
    contacts {
      id
      name
    }
  }
`;

export const OFFICES = gql`
  query Offices {
    offices {
      id
      title
    }
  }
`;

export const MEETING_TYPES = gql`
  query MeetingTypes {
    meetingTypes
  }
`;

export const SUBJECTS = gql`
  query Subjects {
    subjects {
      id
      title
    }
  }
`;

export const OBSERVATION_DATE = gql`
  query ObservationDate {
    observationDate {
      id
      start
      end
    }
  }
`;
