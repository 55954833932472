import React from "react";
import { useTranslation } from "react-i18next";

import { SadEmoji } from "../icons/SadEmoji";
import { CenterMessage } from "./CenterMessage";

interface Props {
  message?: string | null;
}

export function Error(props: Props) {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <CenterMessage
      icon={<SadEmoji />}
      title={t("error.title")}
      text={message ?? t("error.unknown")}
    />
  );
}
