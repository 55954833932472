import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";

import { Error } from "./components/Error";
import { Loader } from "./components/Loader";
import { Sidebar } from "./components/Sidebar";
import { Filters } from "./containers/Filters";
import { Insights } from "./containers/Insights";
import { LOGIN } from "./graphql/mutations";
import { AppStates, Match, useAppStateMachine } from "./hooks/useStateMachine";
import { FilterProvider } from "./providers/FilterProvider";
import { Login } from "./types/api";
import { getToken, setToken } from "./utils/token";

const AUTH_QUERY_PARAM = "accessToken";

function App() {
  const { t } = useTranslation();
  const token = getToken();
  const { currentState, transitionTo } = useAppStateMachine();
  const { error } = currentState.context;

  const [accessTokenParam, setAccessTokenParam] = useQueryParam(
    AUTH_QUERY_PARAM,
    StringParam
  );

  const [login] = useMutation<Login>(LOGIN, {
    onCompleted(data) {
      setAccessTokenParam(undefined);
      setToken(data.login);
      transitionTo({ type: AppStates.READY });
    },
    onError(error) {
      setAccessTokenParam(undefined);
      transitionTo({ type: AppStates.ERROR, error: error.message });
    },
  });

  useEffect(() => {
    if (accessTokenParam != null) {
      login({
        variables: {
          accessToken: accessTokenParam,
        },
      });

      return;
    }

    if (token == null) {
      return transitionTo({
        type: AppStates.ERROR,
        error: t("error.unauthenticated"),
      });
    }

    return transitionTo({ type: AppStates.READY });
  }, [accessTokenParam, login, t, token, transitionTo]);

  return (
    <>
      <Match state={AppStates.LOADING}>
        <Loader />
      </Match>
      <Match state={AppStates.ERROR}>
        <Error message={error} />
      </Match>
      <Match state={AppStates.READY}>
        <FilterProvider>
          <div className="h-screen flex overflow-hidden bg-gray-50">
            <div className="flex flex-shrink-0">
              <div className="flex flex-col w-84">
                <Sidebar>
                  <Filters />
                </Sidebar>
              </div>
            </div>
            <main
              className="flex-1 relative overflow-y-auto focus:outline-none"
              tabIndex={0}
            >
              <Insights />
            </main>
          </div>
        </FilterProvider>
      </Match>
    </>
  );
}

export default App;
