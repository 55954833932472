import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactElement;
}

export function Sidebar(props: Props) {
  const { children } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-0 flex-1 shadow">
      <div className="flex items-center h-16 flex-shrink-0 px-4 bg-brand">
        <img className="h-8 w-auto" src={process.env.REACT_APP_ENTERPRISE_LOGO} alt="Workflow" />
        <span className="ml-10 text-white">{t("title")}</span>
      </div>
      <div className="flex-1 flex flex-col overflow-y-auto">
        <div className="flex-1 px-4 py-4 bg-white space-y-1">{children}</div>
      </div>
    </div>
  );
}
