import sortBy from "lodash.sortby";
import React from "react";
import { useTranslation } from "react-i18next";

import { ICON_MAP } from "../containers/Filters";
import {
  Insights_insights_subjectResponse_records_officeResponse_records as IOffice,
  Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records_contactResponse as IContact,
} from "../types/api";
import { Card } from "./Card";
import { CardBlock } from "./CardBlock";
import { Contact } from "./Contact";

interface Props {
  office: IOffice;
  keyPrefix: string;
}

function renderContact(
  contactResponse: IContact[],
  options: {
    keyPrefix: string;
    externalOfficeId?: number;
  }
) {
  return sortBy(contactResponse, "contact.lastName").map((contact) => (
    <div className="ml-4" key={`${options.keyPrefix}_c${contact.contact.id}`}>
      <Contact contact={contact} externalOfficeId={options.externalOfficeId} />
    </div>
  ));
}

export function Office(props: Props) {
  const { office, keyPrefix } = props;
  const { t } = useTranslation();

  return (
    <Card
      className="mt-8"
      title={office.office.title}
    >
      <>
        {office.meetingTypeResponse.records.map((meetingType) => (
          <CardBlock
            key={`s${keyPrefix}_o${office.office.id}_${meetingType.meetingType}`}
            title={
              <div className="flex items-center">
                {ICON_MAP[meetingType.meetingType]}
                <span className="ml-2">
                  {t(`common:meetingTypes.${meetingType.meetingType.toUpperCase()}`)}
                </span>
              </div>
            }
            actualAvailableSlots={meetingType.actualAvailableSlots}
            duration={meetingType.duration}
            slots={meetingType.slots}
          >
            <>
              {renderContact(meetingType.contactResponse, {
                keyPrefix: `${keyPrefix}_o${office.office.id}_${meetingType.meetingType}`,
                externalOfficeId: office.office.externalId,
              })}
            </>
          </CardBlock>
        ))}
        {office.meetingTypeResponse.records.length > 1 && (
          <CardBlock
            key={`${keyPrefix}_o${office.office.id}_ALL`}
            className="bg-gray-100 rounded-b"
            title="All meeting types"
          >
            <>
              {renderContact(office.meetingTypeResponse.contactResponse, {
                keyPrefix: `${keyPrefix}_o${office.office.id}_ALL`,
                externalOfficeId: office.office.id,
              })}
            </>
          </CardBlock>
        )}
      </>
    </Card>
  );
}
