import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./i18n";
import "typeface-fira-sans";
import "./tailwind.output.css";

import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { QueryParamProvider } from "use-query-params";

import App from "./App";
import { client } from "./graphql/client";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ApolloProvider client={client}>
    <QueryParamProvider>
      <App />
    </QueryParamProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
