import React, { ChangeEvent } from "react";

interface Props {
  invalid?: boolean;
  label: string;
  max: string;
  min: string;
  onChange: (date: string) => void;
  value?: string;
}

export function Datepicker(props: Props) {
  const { invalid, label, max, min, onChange, value } = props;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    return onChange(event.target.value);
  }

  return (
    <div>
      <label className="block text-sm leading-5 mb-1 text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex-grow focus-within:z-10">
          <input
            className={`form-input block w-full rounded-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${
              invalid
                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                : ""
            }`.trimEnd()}
            id={label}
            max={max}
            min={min}
            onChange={handleChange}
            placeholder="dd-mm-yyyy"
            type="date"
            value={value}
          />
        </div>
      </div>
    </div>
  );
}
