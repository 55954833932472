import React from "react";

import { convertMinutes } from "../utils/time";

interface Props {
  actualAvailableSlots: number;
  duration: number;
  slots: number;
}

export function Stats(props: Props) {
  const { actualAvailableSlots, duration, slots } = props;
  const { hours, mins } = convertMinutes(duration);

  return (
    <div className="ml-auto flex">
      <div className="px-2 text-gray-600 flex items-center whitespace-no-wrap">
        <span className="text-l font-bold text-gray-800">{hours}</span>
        <span className="text-sm text-gray-500 ml-1">h</span>
        {mins !== 0 && (
          <>
            <span className="text-l font-bold text-gray-800 ml-1">{mins}</span>
            <span className="text-sm text-gray-500 ml-1">min</span>
          </>
        )}
      </div>
      <div className="text-gray-400">•</div>
      <div className="px-2 text-gray-600 flex items-center whitespace-no-wrap">
        <span className="text-l font-bold text-green-400">
          {actualAvailableSlots}
        </span>
        <span className="text-sm text-gray-500 mx-1">/</span>
        <span className="text-l font-bold text-gray-800">{slots}</span>
        <span className="text-sm text-gray-500 ml-1">slots</span>
      </div>
    </div>
  );
}
