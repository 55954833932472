const TOKEN = "token";

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = (): string | null => localStorage.getItem(TOKEN);

export const clearToken = () => {
  localStorage.removeItem(TOKEN);
};
