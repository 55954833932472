import React from "react";

export function Skeleton() {
  return (
    <div className="animate-pulse">
      {Array.from(Array(5).keys()).map((key) => (
        <div key={key} className="mt-16 first:mt-0">
          <div className="flex space-x-24 mb-12 px-6">
            <div className="h-6 bg-gray-400 rounded flex-1" />
            <div className="h-6 bg-gray-400 rounded w-40 ml-auto" />
          </div>
          <div className="h-24 bg-gray-400 rounded flex-1" />
        </div>
      ))}
    </div>
  );
}
