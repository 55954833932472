import React, { ReactElement, useState } from "react";

import { ChevronDown } from "../icons/ChevronDown";
import { ChevronRight } from "../icons/ChevronRight";
import { Stats } from "./Stats";

interface Props {
  actualAvailableSlots?: number;
  children: ReactElement;
  className?: string;
  title: string | ReactElement | ReactElement[];
  duration?: number;
  slots?: number;
}

export function CardBlock(props: Props) {
  const { actualAvailableSlots, children, className = "", title, duration, slots } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  function toggleIsCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div
      className={`border-b border-gray-200 last:border-b-0 bg-white pl-12 pr-6 py-2 ${className}`.trim()}
    >
      <div className="flex">
        <button
          className="py-2 flex flex-1 items-center select-none focus:outline-none"
          onClick={toggleIsCollapsed}
        >
          {isCollapsed ? <ChevronRight /> : <ChevronDown />}
          <h3 className="text-l font-semibold text-gray-900 capitalize ml-4">
            {title}
          </h3>
          {actualAvailableSlots !== undefined && duration !== undefined && slots !== undefined && (
            <Stats actualAvailableSlots={actualAvailableSlots} duration={duration} slots={slots} />
          )}
        </button>
      </div>

      {!isCollapsed && <div>{children}</div>}
    </div>
  );
}
