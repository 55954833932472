import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { components, createFilter } from "react-select";
import { MenuListComponentProps } from "react-select/src/components/Menu";
import { OptionProps } from "react-select/src/components/Option";
import { FixedSizeList } from "react-window";

const optimizeSelect = {
  components: {
    MenuList: OptimizedMenuList,
    Option: OptimizedOption,
  },
};

function SelectMenuNoOptions() {
  return <div className="text-gray-400 py-3 text-center">No Options</div>;
}

function OptimizedMenuList(props: MenuListComponentProps<SelectOption, true>) {
  const { options, children, maxHeight, getValue } = props;
  if (!children || !Array.isArray(children)) {
    return <SelectMenuNoOptions />;
  }

  const height = 35;
  const totalHeight = height * children.length;
  const selectedValues = getValue() as SelectOption[];
  const initialOffset = selectedValues[0]
    ? options.indexOf(selectedValues[0]) * height
    : 0;

  return (
    <FixedSizeList
      className="my-1"
      height={totalHeight > maxHeight ? maxHeight : totalHeight}
      initialScrollOffset={initialOffset}
      itemCount={children.length}
      itemSize={height}
      width={""}
    >
      {({ index, style }) => (
        <div className="option-wrapper" style={style}>
          {children[index]}
        </div>
      )}
    </FixedSizeList>
  );
}

function OptimizedOption(props: OptionProps<SelectOption, true>) {
  // @ts-ignore
  delete props.innerProps.onMouseMove;
  // @ts-ignore
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
}

type SelectOption = {
  value: string;
  label: string;
  [key: string]: string;
};

type OptionType = { label: string; value: string };

interface Props {
  label: string;
  onChange: (ids: string[]) => void;
  options: OptionType[];
  placeholder?: string;
  value: any[];
  optimizeList?: boolean;
}

export function SelectDropdown(props: Props) {
  const { label, onChange, optimizeList, options, placeholder, value } = props;
  const { t } = useTranslation();

  const mappedValues = useMemo(() => {
    return options.filter((option) => value.indexOf(option.value) !== -1);
  }, [options, value]);

  const handleChange = useCallback(
    (options: any) => {
      return onChange(options?.map((option: OptionType) => option.value) ?? []);
    },
    [onChange]
  );

  return (
    <div>
      <label className="block text-sm leading-5 mb-1 text-gray-700">
        {label}
      </label>
      <Select
        className="text-sm"
        classNamePrefix="select"
        closeMenuOnSelect={false}
        components={optimizeList ? optimizeSelect.components : undefined}
        filterOption={createFilter({ ignoreAccents: false })}
        isMulti
        name="colors"
        onChange={handleChange}
        options={options}
        placeholder={placeholder ?? t("select.placeholder")}
        value={mappedValues}
      />
    </div>
  );
}
