import React from "react";

import { Open } from "../icons/Open";
import { Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records_contactResponse as IContact } from "../types/api";
import { Stats } from "./Stats";

interface Props {
  contact: IContact;
  externalOfficeId?: number;
}

function getAvailabilityTemplateUrl(
  externalOfficeId: number,
  externalContactId: number
) {
  return `${process.env.REACT_APP_SKEDIFY_APP_URL}/team/${externalOfficeId}/members/${externalContactId}/availability`;
}

export function Contact(props: Props) {
  const { contact, externalOfficeId } = props;

  return (
    <div className="my-2">
      <div className="flex space-x-2 items-center hover:bg-blue-50 rounded cursor-default">
        <div className="py-1 px-2 rounded flex-1 flex items-center">
          <span className="ml-2 text-gray-700">
            {contact.contact.firstName}{" "}
            <strong>{contact.contact.lastName}</strong>
          </span>
          {externalOfficeId && (
            <a
              className="ml-4 text-gray-400 text-sm"
              href={getAvailabilityTemplateUrl(
                externalOfficeId,
                contact.contact.externalId
              )}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Open />
            </a>
          )}
        </div>
        <Stats actualAvailableSlots={contact.actualAvailableSlots} duration={contact.duration} slots={contact.slots} />
      </div>
    </div>
  );
}
