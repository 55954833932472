/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login {
  login: string;
}

export interface LoginVariables {
  accessToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Insights
// ====================================================

export interface Insights_insights_contactResponse_contact {
  id: number;
  externalId: number;
  firstName: string | null;
  lastName: string | null;
}

export interface Insights_insights_contactResponse {
  actualAvailableSlots: number;
  contact: Insights_insights_contactResponse_contact;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
}

export interface Insights_insights_subjectResponse_records_subject {
  id: number;
  title: string;
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_office {
  id: number;
  externalId: number;
  title: string;
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records_contactResponse_contact {
  id: number;
  externalId: number;
  firstName: string | null;
  lastName: string | null;
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records_contactResponse {
  actualAvailableSlots: number;
  contact: Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records_contactResponse_contact;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records {
  meetingType: MeetingType;
  actualAvailableSlots: number;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
  contactResponse: Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records_contactResponse[];
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_contactResponse_contact {
  id: number;
  externalId: number;
  firstName: string | null;
  lastName: string | null;
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_contactResponse {
  actualAvailableSlots: number;
  contact: Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_contactResponse_contact;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
}

export interface Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse {
  records: Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_records[];
  contactResponse: Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse_contactResponse[];
}

export interface Insights_insights_subjectResponse_records_officeResponse_records {
  office: Insights_insights_subjectResponse_records_officeResponse_records_office;
  actualAvailableSlots: number;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
  meetingTypeResponse: Insights_insights_subjectResponse_records_officeResponse_records_meetingTypeResponse;
}

export interface Insights_insights_subjectResponse_records_officeResponse_contactResponse_contact {
  id: number;
  externalId: number;
  firstName: string | null;
  lastName: string | null;
}

export interface Insights_insights_subjectResponse_records_officeResponse_contactResponse {
  actualAvailableSlots: number;
  contact: Insights_insights_subjectResponse_records_officeResponse_contactResponse_contact;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
}

export interface Insights_insights_subjectResponse_records_officeResponse {
  records: Insights_insights_subjectResponse_records_officeResponse_records[];
  contactResponse: Insights_insights_subjectResponse_records_officeResponse_contactResponse[];
}

export interface Insights_insights_subjectResponse_records {
  subject: Insights_insights_subjectResponse_records_subject;
  actualAvailableSlots: number;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
  officeResponse: Insights_insights_subjectResponse_records_officeResponse;
}

export interface Insights_insights_subjectResponse_contactResponse_contact {
  id: number;
  externalId: number;
  firstName: string | null;
  lastName: string | null;
}

export interface Insights_insights_subjectResponse_contactResponse {
  actualAvailableSlots: number;
  contact: Insights_insights_subjectResponse_contactResponse_contact;
  slots: number;
  /**
   * Duration in minutes
   */
  duration: number;
}

export interface Insights_insights_subjectResponse {
  records: Insights_insights_subjectResponse_records[];
  contactResponse: Insights_insights_subjectResponse_contactResponse[];
}

export interface Insights_insights {
  start: any;
  end: any;
  totalRecords: number;
  contactResponse: Insights_insights_contactResponse[];
  subjectResponse: Insights_insights_subjectResponse;
}

export interface Insights {
  insights: Insights_insights;
}

export interface InsightsVariables {
  params: InsightsParams;
  filters?: InsightsFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Contacts
// ====================================================

export interface Contacts_contacts {
  id: number;
  name: string;
}

export interface Contacts {
  contacts: Contacts_contacts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Offices
// ====================================================

export interface Offices_offices {
  id: number;
  title: string;
}

export interface Offices {
  offices: Offices_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeetingTypes
// ====================================================

export interface MeetingTypes {
  meetingTypes: MeetingType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Subjects
// ====================================================

export interface Subjects_subjects {
  id: number;
  title: string;
}

export interface Subjects {
  subjects: Subjects_subjects[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ObservationDate
// ====================================================

export interface ObservationDate_observationDate {
  id: number;
  start: any;
  end: any;
}

export interface ObservationDate {
  observationDate: ObservationDate_observationDate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MeetingType {
  OFFICE = "OFFICE",
  ON_LOCATION = "ON_LOCATION",
  PHONE = "PHONE",
  VIDEO = "VIDEO",
}

/**
 * Possible aggregation orders, must be passed as array
 */
export enum order {
  CONTACT = "CONTACT",
  MEETING_TYPE = "MEETING_TYPE",
  OFFICE = "OFFICE",
  SUBJECT = "SUBJECT",
}

export interface InsightsFilters {
  contactId?: number[] | null;
  meetingType?: MeetingType[] | null;
  officeId?: number[] | null;
  subjectId?: number[] | null;
}

export interface InsightsParams {
  aggregationOrder: order[];
  end: any;
  start: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
