import { useLazyQuery } from "@apollo/client";
import { add } from "date-fns";
import React, { createContext, ReactElement, useState } from "react";

import { INSIGHTS } from "../graphql/queries";
import { Insights, Insights_insights, MeetingType } from "../types/api";

const DEFAULT_AGGREGATION_ORDER = [
  "SUBJECT",
  "OFFICE",
  "MEETING_TYPE",
  "CONTACT",
];

interface Props {
  children: ReactElement;
}
export interface Filters {
  contactIds: number[];
  end?: string;
  meetingTypes: MeetingType[];
  officeIds: number[];
  start?: string;
  subjectIds: number[];
}

interface FilterContextProps {
  insights?: Insights_insights | null;
  loading: boolean;
  onFilter: (filters: Filters) => void;
  onReset: () => void;
  error: string | null;
}

export const defaultFilters = {
  contactIds: [],
  meetingTypes: [
    MeetingType.OFFICE,
    MeetingType.PHONE,
    MeetingType.ON_LOCATION,
    MeetingType.VIDEO,
  ],
  officeIds: [],
  subjectIds: [],
  start: new Date(),
  end: add(new Date(), { months: 1 }),
};

export const FilterContext = createContext<FilterContextProps>({
  insights: undefined,
  loading: false,
  onFilter: () => null,
  onReset: () => null,
  error: null
});

export function FilterProvider(props: Props) {
  const { children } = props;
  const [insights, setInsights] = useState<
    Insights_insights | undefined | null
  >();
  const [error, setError] = useState<string | null>(null);

  const [queryInsights, { loading }] = useLazyQuery<Insights>(INSIGHTS, {
      errorPolicy: "all",
    onCompleted: (data) => {
      setInsights(data.insights);
    },
    onError: (error => {
      if (error.graphQLErrors != null && error.graphQLErrors.length > 0) {
        setError('error.technical');
      }

      if (error.networkError != null) {
        setError('error.timeout');
      }
    })
  });

  function onFilter(filters: Filters) {
    queryInsights({
      variables: {
        filters: {
          contactId: filters.contactIds,
          officeId: filters.officeIds,
          subjectId: filters.subjectIds,
          meetingType: filters.meetingTypes,
        },
        params: {
          aggregationOrder: DEFAULT_AGGREGATION_ORDER,
          start: filters.start,
          end: filters.end,
        },
      },
    });
  }

  function onReset() {
    setInsights(undefined);
  }

  return (
    <FilterContext.Provider value={{ onFilter, onReset, loading, insights, error }}>
      {children}
    </FilterContext.Provider>
  );
}
