import React, { ChangeEvent, ReactElement } from "react";

interface Props {
  label: string;
  onChange: (values: string[]) => void;
  options: { value: string; label: string; icon?: ReactElement }[];
  value: string[];
}

export function CheckboxGroup(props: Props) {
  const { label, onChange, options, value } = props;

  function handleToggle(event: ChangeEvent<HTMLInputElement>) {
    const option = event.target.name;

    if (event.target.checked) {
      return onChange([...value, option]);
    }

    return onChange(value.filter((el) => el !== option));
  }

  return (
    <fieldset className="mt-4">
      <label className="block text-sm leading-5 mb-1 text-gray-700">
        {label}
      </label>
      <div className="ml-2">
        {options.map((option) => (
          <div className="mt-4" key={option.value}>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  name={option.value}
                  checked={value.includes(option.value)}
                  onChange={handleToggle}
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                />
              </div>
              <div className="ml-3 text-sm leading-5">
                <label htmlFor="phone" className="text-gray-700">
                  {option.icon}
                  <span className="ml-2">{option.label}</span>
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
