import React from "react";

export function FilterSkeleton() {
  return (
    <div className="animate-pulse space-y-6">
      {Array.from(Array(4).keys()).map((key) => (
        <div className="flex-1 space-y-3" key={key}>
          <div className="h-5 bg-gray-400 rounded w-16" />
          <div className="h-10 bg-gray-400 rounded" />
        </div>
      ))}
      <div className="flex-1 space-y-3">
        <div className="h-5 bg-gray-400 rounded w-16" />
        {Array.from(Array(4).keys()).map((key) => (
          <div className="flex space-x-2" key={key}>
            <div className="h-5 bg-gray-400 rounded w-6" />
            <div className="h-5 bg-gray-400 rounded w-24" />
          </div>
        ))}
      </div>
    </div>
  );
}
