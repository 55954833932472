import React, { ReactElement } from "react";

interface Props {
  children?: ReactElement;
  icon: ReactElement;
  text: string | ReactElement;
  title: string;
}

export function CenterMessage(props: Props) {
  const { children, icon, text, title } = props;

  return (
    <div className="flex flex-col h-screen items-center justify-center text-center">
      <div className="p-5">{icon}</div>
      <h3 className="text-lg leading-6 font-medium text-gray-900 max-w-sm">
        {title}
      </h3>
      <p className="text-gray-700 p-1 max-w-sm">{text}</p>
      {children}
    </div>
  );
}
