import { useService } from "@xstate/react";
import { ReactElement } from "react";
import { assign, interpret, Machine } from "xstate";

export enum AppStates {
  ERROR = "ERROR",
  LOADING = "LOADING",
  READY = "READY",
}

interface AppStateSchema {
  states: {
    [AppStates.ERROR]: {};
    [AppStates.LOADING]: {};
    [AppStates.READY]: {};
  };
  context: {
    error: null | string;
  };
}

interface AppContext {
  error?: string | null;
}

type AppEvent =
  | { type: "ERROR"; error?: string | null }
  | { type: "LOADING"; error?: string | null }
  | { type: "READY"; error?: string | null };

const appStateMachine = Machine<AppContext, AppStateSchema, AppEvent>(
  {
    key: "app",
    initial: AppStates.LOADING,
    context: {
      error: null,
    },
    states: {
      LOADING: {
        on: {
          READY: AppStates.READY,
          ERROR: AppStates.ERROR,
        },
        entry: ["onError"],
      },
      READY: {
        type: "final",
        entry: ["onError"],
      },
      ERROR: {
        type: "final",
        entry: ["onError"],
      },
    },
  },
  {
    actions: {
      onError: assign({
        error: (_, event) => event.error,
      }),
    },
  }
);

const appMachineService = interpret(appStateMachine).start();

export function useAppStateMachine() {
  const [currentState, transition] = useService(appMachineService);

  return {
    currentState,
    transitionTo: transition,
  };
}

export function Match({
  children,
  state,
}: {
  children: ReactElement;
  state: AppStates;
}) {
  const { currentState } = useAppStateMachine();

  if (currentState.matches(state)) {
    return children;
  }

  return null;
}
